.login-container {
    background: linear-gradient(150deg, #087830, #143b22, rgb(19, 32, 20)) !important;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100vh;
}

.login-grid {
    margin: 0em !important;
    height: 100vh;
}

.login-segment {
    background: #535E51 !important;
    border-radius: 10px !important;
    width: 80% !important;
    margin: -15% auto auto !important;
    padding-top: 15% !important;
}

.login-logo {
    margin: auto;
    z-index: 1 !important;
}

.login-button {
    margin: 5% auto 0% !important;
}
.login-button:hover {
    color: #535E51 !important;
}

.login-message {
    color: #FFFFFF !important;
}

.choose-org {
    width: 75% !important;
    margin: auto !important;
}

.proceed-button {
    width: 85% !important;
    margin: auto !important;
}

@media only screen and (max-width: 768px) {
    .proceed-button {
        margin: auto !important;
    }   
    .login-segment {
        background: #535E51 !important;
        border-radius: 10px !important;
        width: 90% !important;
        margin: -20% auto auto !important;
        padding-top: 15% !important;
    }
    .choose-org {
        width: 100% !important;
    }
    
}