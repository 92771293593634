/* GENERAL */
.ui.disabled.dropdown, .ui.dropdown .menu>.disabled.item {
    opacity: 1 !important;
}
.page-content {
    margin: 75px 20px 30px;
}
.welcome-text {
    opacity: 1;
}
.welcome-text:hover {
    background: none !important;
    opacity: 0.75;
}
.menu-header, .icons {
    color: #FFFFFF !important;
    opacity: 1;
}
.icons:hover {
    opacity: 0.75;
}
.sidebar-menu {
    background: linear-gradient(150deg, #087830, #143b22, rgb(19, 32, 20)) !important;
}
.user-menu {
    margin: 0px !important;
    padding: 0px 2.5% 0px !important;
    border-radius: 0px !important;
    border: none !important;
    background: linear-gradient(150deg, #087830, #143b22, rgb(19, 32, 20)) !important;
}
.menu-grid {
    color: #FFFFFF;
    margin: 0em !important;
}
.page-segment {
    display: flex;
    height: 100vh;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0px !important;
    border: none !important;
    border-radius: 0px !important;
    background-color: #ECF4EE !important;
}
.dimmer-content {
    flex: 1 !important;
}
.content-pusher {
    border-radius: 0px !important;
    border: none !important;
    margin: 0px !important;
}
.page-buttons {
    background: #087830 !important;
    color: #FFFFFF !important;
}
.fin-logo {
    filter: brightness(0) invert(1);
    margin-right: 20px !important;
}

/* MODAL */
.web-modal {
    animation: fade 0.25s;    
}
.modal-header {
    background: linear-gradient(150deg, #087830, #143b22, rgb(19, 32, 20)) !important;
    color: #FFFFFF !important;
    border-radius: 0px !important;
}
.modal-buttons {
    background: #087830 !important;
    color: #FFFFFF !important;
    margin-bottom: 3% !important;
}

/* CARDS */
.cards-title {
    font-size: 1.25em;
}
.link-card {
    font-size: 1.25em !important;
    min-height: 35vh !important;
    max-width: 90% !important;
    color: #000000;
    padding-top: 15px !important;
    margin: 20px !important;
    border-radius: 15px !important;
    box-shadow: 10px 15px 15px 1px #888888 !important;
}
.link-card:hover {
    color: #000000;
}
.card-desc {
    padding-top: 5%;
    font-size: 0.95em;
    color: #888888;
}
.card-grid {
    height: 100% !important;
    margin: auto !important;
}
.card-buttons {
    color: #087830 !important;
}
.card-buttons-wrapper {
    margin-top: auto !important;
    margin-bottom: 10% !important;
    display: flex !important;
    justify-content: space-evenly !important;
}
.popup-description {
    max-width: 400px !important;
}
.space-grid {
    padding: 15px 0 5px !important;
}


/* ANNOUNCEMENT */
.announcement-header {
    font-size: 1.05em;    
    padding: auto 20px !important;
}
.announcement-action-wrapper {
    padding-top: 10px;
    display: flex;
    justify-content: space-evenly;
}

/* DOCUMENT TRACKER */
.docu-list {
    background: #ECF4EE;
    padding: 0px 10px 10px !important;
}
.docu-list:hover {
    cursor: pointer;
    background: #DCE6DF;
}
.filter-buttons {
    width: 100px;
}
.pagination-wrapper {
    margin: 5% !important;
    display: flex;
    justify-content: center;
}

/** SETTINGS **/
.settings-title {
    padding-left: 5% !important;
}
.user-name-settings {
    padding-left: 5px !important;
}


@keyframes fade {
    0% {
        opacity: 0;
        transform: scale(0)
    }
    100% {
        opacity: 1;
        transform: scale(1)
    }
}

@media only screen and (max-width: 992px) {
    .page-content {
        margin: 75px 20px 30px;
    }
    .link-card {
        font-size: 1.25em !important;
        min-height: 40vh;
        max-width: 90% !important;
        color: #000000;
        padding-bottom: 20px !important;
        margin: 20px !important;
        border-radius: 15px !important;
        box-shadow: 10px 15px 15px 1px #888888 !important;
    }
    .card-buttons-wrapper {
        margin: 5% 0 !important;
        display: flex !important;
        justify-content: space-evenly !important;
    }
    .pagination-wrapper {
        margin: 10% !important;
        display: flex;
        justify-content: center;
    }
    .fin-logo {
        filter: brightness(0) invert(1);
        margin: auto !important;
    }
    .settings-title {
        padding-left: 2.5% !important;
        text-align: center;
    }
}