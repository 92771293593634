.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.App-link {
    color: #61dafb;
}

.web-grid {
    margin: 0em !important;
}
.compact-element {
    margin: 0 !important;
    padding: 0 !important;
}
.invisible-element {
    visibility: hidden !important;
    margin: 0 !important;
    padding: 0 !important;
}
.footer-wrapper {
    background: #272727 !important;
    height: 10vh;
}
.footer-grid {
    margin: 0em 5em !important;
    color: #FFFFFF;
}
.footer-text-wrapper {
    line-height: 2em;
}

@media only screen and (max-width: 768px) {
    .footer-grid {
        margin: 0em 1em !important;
        color: #FFFFFF;
    }
}